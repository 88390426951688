const spacingUnit = 10;

export const muiDefaults = {
  palette: {
    primary: {
      // light: '#757ce8',
      main: '#004d40',
      dark: '#00251a',
      // contrastText: '#fff',
    },
    secondary: {
      // light: "#ff7961",
      main: '#39796b',
      // dark: "#ba000d",
      // contrastText: "#000",
    },
    info: {
      main: '#ffeb3b',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    // fontSize: 24,
  },
  shape: {
    borderRadius: 8,
  },
  spacing: spacingUnit,
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: `rgba(5,34,40,.7)`,
      },
    },
    MuiDialogActions: {
      root: {
        margin: spacingUnit * 2 + 'px',
        marginTop: 0,
      },
    },
    // MuiButton: {
    //   root: {
    //     fontSize: "1.2rem",
    //   },
    // },
  },
};
