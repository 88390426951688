import React from "react";
import { Link } from "./Link";
import { withRouter, NextRouter } from "next/router";
import styled from "styled-components";
import { NavigationItems } from "./NavigationItems";
import Image from "next/image";
import { UserAvatar } from "./UserAvatar";
export interface INavBarItem {
  title: string;
  navigation: string;
  icon: string;
  activeIcon: string;
}

const Container = styled.div`
  /* z-index: 11; */

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background: ${({ theme }) => theme.backgroundNavigation};

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 1rem 0;
    /* padding: 0.5rem; */
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  /* justify-content: center; */
  cursor: pointer;
  padding-left: 1.4rem;
  padding-bottom: 0.5rem;
`;

interface Props {
  menuConfig: INavBarItem[];
  showTitle: boolean;
  router: NextRouter;
}

const _NavigationBar = ({ menuConfig, showTitle }: Props) => {
  return (
    <Container>
      <Link href="/">
        <LogoContainer>
          <Image src="/logo-main.svg" width={200} height={75} />
        </LogoContainer>
      </Link>
      <NavigationItems />
      <UserAvatar />

    </Container>
  );
};

export const NavigationBar = withRouter(_NavigationBar);
