import styled from "styled-components";

export const NewTopBarContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  width: 100%; */
  /* padding-top: 0.3rem; */
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  width: 100%;
  padding-top: 5px;
  padding: 5px 10px 0;

`;
