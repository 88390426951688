export interface IThemeColors {
  backgroundActive: string;
  backgroundLight: string;
  backgroundMain: string;
  backgroundHeader: string;
  backgroundNavigation: string;
  cardFontColor: string;
  cardBackgroundColor: string;
  colorMain: string;
  colorSecondary: string;
  colorActive: string;
  colorLight: string;

  borderColor: string;

  TableBackground: string;
  TableHighlightBackground: string;
  TableLabelColor: string;

  SimpleButtonColor: string;
  SimpleButtonBorderColor: string;
}

export const colors = {
  white: '#fff',
};

export type IColors = typeof colors;

export const light: IThemeColors = {
  backgroundLight: '#f4f4f8',
  backgroundMain: colors.white,
  backgroundActive: '#00251a',
  backgroundHeader: '#004d40',
  backgroundNavigation: '#004d40',
  cardFontColor: '#ffeb3b',
  cardBackgroundColor: '#00251a',

  colorMain: '#004d40',
  colorSecondary: '#39796b',
  colorActive: '#FFC000',
  // colorActive: "#0ec782",
  colorLight: '#999999',

  borderColor: '#999999',

  TableBackground: '#fafafb',
  TableHighlightBackground: colors.white,
  TableLabelColor: '#8686a0',

  SimpleButtonColor: '#44444f',
  SimpleButtonBorderColor: '#e2e2ea',
};

export const dark: IThemeColors = {
  backgroundLight: '#1c1c24',
  backgroundMain: '#13131a',
  backgroundActive: '#122e29',
  backgroundHeader: '#004d40',
  backgroundNavigation: '#004d40',
  cardFontColor: '#ffeb3b',
  cardBackgroundColor: '#00251a',

  colorMain: colors.white,
  colorSecondary: '#39796b',

  colorActive: '#0ec782',
  colorLight: '#8686a0',

  borderColor: '#44444f',

  TableBackground: '#23232d',
  TableHighlightBackground: '#1c1c24',
  TableLabelColor: '#92929d',

  SimpleButtonColor: '#92929d',
  SimpleButtonBorderColor: '#44444f',
};
