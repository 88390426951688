import { createGlobalStyle } from "styled-components";
import { modeProperty } from "./modeProperty";

export const GlobalStyle = createGlobalStyle`
  body {
    font-size: 0.875rem;
    font-family: ${({ theme }) => theme.baseFont};
    font-weight: 400;
    background: ${({ theme }) => theme.backgroundMain};
    color: ${({ theme }) => theme.colorMain};
    ${({ theme }) => theme.breakpoints.down("md")} {
      ${modeProperty("light", "background", "#fafafb")};
    }
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;
