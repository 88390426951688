import React, { useEffect } from 'react';
import Link from 'next/link';
import Divider from '@material-ui/core/Divider';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import { NextRouter, useRouter, withRouter } from 'next/router';
import styled from 'styled-components';
import Image from 'next/image';
import { UserAvatar } from './UserAvatar';
import { NavigationItems } from './NavigationItems';
import { navigationConfig } from '../config/navigation';

// import IconButton from '@material-ui/core/IconButton';
// import Close from '@material-ui/icons/Close';
// import Fab from '@material-ui/core/Fab';
// import { orange } from '@material-ui/core/colors';

const drawerWidth = 240;

const StyledDrawer = styled(SwipeableDrawer)`
  &&& {
    .MuiDrawer-paper {
      background-color: ${({ theme }) => theme.colorMain};
      color: white;
      padding: 0.5rem 0 1rem;
      display: grid;
      grid-template-rows: auto 1fr auto;
      width: ${drawerWidth}px;
    }
  }
  &&&.MuiDrawer-root {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  /* justify-content: center; */
  cursor: pointer;
  padding-left: 1.4rem;
  padding-bottom: 0.5rem;
`;

interface PropsSideMenu {
  router: NextRouter;
  open: boolean;
  handleDrawerChange: React.Dispatch<React.SetStateAction<boolean>>;
}

const _SideMenu = ({ open, handleDrawerChange }: PropsSideMenu) => {
  const router = useRouter();

  useEffect(() => {
    if (open) {
      handleDrawerChange(false);
    }
  }, [router.asPath]);

  return (
    <StyledDrawer
      anchor="left"
      open={open}
      onClose={() => handleDrawerChange(false)}
      onOpen={() => handleDrawerChange(true)}
      // classes={{
      //   paper: classes.drawerPaper,
      // }}
    >
      <Link href="/">
        <LogoContainer>
          <Image src="/logo-main.svg" width={150} height={50} />
        </LogoContainer>
      </Link>
      {/* <Fab aria-label="like" color="secondary" size="small">
        <Close />

      </Fab>
      <IconButton onClick={() => handleDrawerChange(false)}>
        <Close />
      </IconButton> */}
      <div>
        <Divider />
        <NavigationItems />
      </div>
      <UserAvatar />
    </StyledDrawer>
  );
};

export const SideMenu = withRouter(_SideMenu);
