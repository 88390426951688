import { useContext } from 'react';

import { AppContext, AppContextProps } from '../context/app';

export const useAppContext = () => {
  const context = useContext<AppContextProps>(AppContext);

  if (!context) {
    throw new Error('useAppContext hook must be used within a Provider AppContext');
  }

  return context;
};
