import React, { useState } from 'react';
import styled from 'styled-components';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import StarBorderIcon from '@material-ui/icons/Star';
import Fade from '@material-ui/core/Fade';
import AppBar from '@material-ui/core/AppBar';
import { NewTopBarContainer } from './TopBarStyles';

import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import { SideMenu } from './SideMenu';
import { useAppContext } from '../hooks/useAppContext';
import { Star } from './Star';

const StyledAppBar = styled(AppBar)`
  &&&.MuiAppBar-root {
    background-color: ${({ theme }) => theme.colorMain};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  /* justify-content: center; */
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('md')} {
    /* margin-left: 20px; */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  /* padding: 0 1rem 0 0; */
  align-items: center;

  button {
    font-size: 0.8rem;

    &:not(:first-child) {
      margin-left: 0.4rem;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 1.5rem;
    button {
      margin-left: 0.6rem;
    }
  }
`;
const StyledStarBorderIcon = styled(StarBorderIcon)`
  color: ${({ theme }) => theme.colorActive};
`;

const StyledMenuIcon = styled(MenuIcon)`
  color: ${({ theme }) => theme.backgroundLight};
`;

const StyledUserInfoText = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: 10px;
`;

const StyledPageTitle = styled.h3`
  color: ${({ theme }) => theme.backgroundLight};
  padding-left: 1rem;
`;

export const TopBar = () => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { pageTitle } = useAppContext();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledAppBar position="static" color="default" elevation={0}>
      <NewTopBarContainer>
        {/* <Link href="/">
          <LogoContainer>
            <Image src="/logo-main.svg" width={150} height={50} />
          </LogoContainer>
        </Link> */}
        <Hidden mdUp>
          <LogoContainer>
            <IconButton onClick={() => setIsHamburgerMenuOpen(true)}>
              <StyledMenuIcon />
            </IconButton>
            <SideMenu open={isHamburgerMenuOpen} handleDrawerChange={setIsHamburgerMenuOpen} />
          </LogoContainer>
          <StyledPageTitle>{pageTitle}</StyledPageTitle>
          <ButtonContainer>
            {/* <Fab variant="extended" size="small">
              Subskrypcja
            </Fab> */}
            {/* <Fab aria-label="like" color="secondary" size="small">
            <StyledStarBorderIcon />
          </Fab> */}

            {/* <Star checked iconSize="large" badgeCount={4} color="colorActive" /> */}
          </ButtonContainer>
        </Hidden>
      </NewTopBarContainer>
    </StyledAppBar>
  );
};
