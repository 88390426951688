import { useMemo, useState } from "react";
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { dark, light } from "./themeColors";
import { muiDefaults } from "./muiDefaults";
import { CSSVars } from "./variables";
import { GlobalStyle } from "./GlobalStyles";

type ThemeProviderProps = {
  children: React.ReactNode;
};
export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState("light");

  const muiTheme = useMemo(() => {
    const colors = theme === "dark" ? dark : light;
    const customTheme = {
      mode: theme,
      ...colors,
      ...muiDefaults,
      ...CSSVars,
    };
    return createMuiTheme(customTheme);
  }, [theme]);
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <StyledThemeProvider theme={muiTheme}>
          <CssBaseline />
          <GlobalStyle />
          {children}
        </StyledThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};
