import { INavBarItem } from '../components/Navigation';
import { RouterConfig } from './router';

export const navigationConfig: INavBarItem[] = [
  {
    title: 'Mecze',
    navigation: RouterConfig.AllMatches,
    icon: '/icon-all-active.svg',
    activeIcon: '/icon-all.svg',
  },
  {
    title: 'Under/Over',
    navigation: RouterConfig.Under,
    activeIcon: '/icon-over.svg',
    icon: '/icon-over-active.svg',
  },
  {
    title: 'Remisy',
    navigation: RouterConfig.Draw,
    activeIcon: '/icon-draws.svg',
    icon: '/icon-draws-active.svg',
  },
  {
    title: 'BTTS',
    navigation: RouterConfig.Btts,
    activeIcon: '/icon-btts.svg',
    icon: '/icon-btts-active.svg',
  },
  {
    title: 'Żółte Kartki',
    navigation: RouterConfig.YellowCard,
    activeIcon: '/icon-yellow.svg',
    icon: '/icon-yellow-active.svg',
  },
  {
    title: 'Czerwone Kartki',
    navigation: RouterConfig.RedCard,
    activeIcon: '/icon-red.svg',
    icon: '/icon-red-active.svg',
  },
  {
    title: 'Rożne',
    navigation: RouterConfig.Corner,
    activeIcon: '/icon-corners.svg',
    icon: '/icon-corners-active.svg',
  },
  {
    title: 'Spalone',
    navigation: RouterConfig.Offside,
    activeIcon: '/icon-offside.svg',
    icon: '/icon-offside-active.svg',
  },
  {
    title: 'Faule',
    navigation: RouterConfig.Foul,
    activeIcon: '/icon-foul.svg',
    icon: '/icon-foul-active.svg',
  },
  {
    title: 'Łamak',
    navigation: RouterConfig.Breaker,
    activeIcon: '/icon-foul.svg',
    icon: '/icon-foul-active.svg',
  },
  {
    title: 'Late',
    navigation: RouterConfig.Late,
    activeIcon: '/icon-late.svg',
    icon: '/icon-late-active.svg',
  },
  // {
  //   title: 'Predykcje',
  //   navigation: RouterConfig.Prediction,
  //   activeIcon: '/icon-late.svg',
  //   icon: '/icon-late-active.svg',
  // },
];
