export class Analytics {
  static pageView(url: string) {
    (window as any)?.gtag?.('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
  }

  static trackEvent(eventName: string, eventParams: any) {
    (window as any)?.gtag?.('event', eventName, eventParams);
  }
}
