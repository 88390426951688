import { Theme } from "@material-ui/core/styles";

export const modeProperty = (
  mode: string,
  property: string,
  value: string | ((theme: any) => string)
) => {
  if (typeof value === "string") {
    return ({ theme }) => theme.mode === mode && `${property}: ${value}`;
  } else {
    const fn = value;
    return ({ theme }) => theme.mode === mode && `${property}: ${fn(theme)}`;
  }
};
