import React, { useRef, useEffect } from 'react';
import type { AppProps } from 'next/app';

import Head from 'next/head';
import { ThemeProvider } from '../components/Theme/ThemeProvider';
import { Layout } from '../components/Layout';
import { SessionProvider } from 'next-auth/react';
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import '../styles/globals.css';
import { ToastProvider } from '../context/toast';
import { AppContextProvider } from '../context/app';
import { StatsChangeContextProvider } from '../context/statsChange';
import Script from 'next/script';
export { reportWebVitals } from 'next-axiom';
import { useRouter } from 'next/router';
import { Analytics } from '../services/analytics';
import { CookiesProvider } from 'react-cookie';

export default function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const router = useRouter();

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: 2,
          staleTime: Infinity, // 30seconds
          cacheTime: 1000 * 30, //30 seconds
        },
      },
    }),
  );

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      Analytics.pageView(url);
    };

    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>Staty</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
        <meta charSet="utf-8" />
        <meta name="description" content="Description" />
        <meta name="keywords" content="Keywords" />
        <meta name="theme-color" content="#ffeb3b" />

        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link rel="preload" as="style" href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap" />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap" rel="stylesheet" />
        <noscript>
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap" rel="stylesheet" />
        </noscript>
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon.png" />
        <link rel="apple-touch-icon" href="/icons/apple-icon.png" />
      </Head>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} strategy="lazyOnload" />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
        try {

          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag?.('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        } catch (error) {
          console.log("🚀 ~ file: _app.tsx ~ line 88 ~ MyApp ~ error", error)
        }
        `}
      </Script>
      <QueryClientProvider client={queryClient.current}>
        <Hydrate state={pageProps.dehydratedState}>
          <SessionProvider session={session}>
            <ThemeProvider>
              <CookiesProvider>
                <ToastProvider>
                  <AppContextProvider>
                    <StatsChangeContextProvider>
                      <Layout>
                        <Component {...pageProps} />
                      </Layout>
                    </StatsChangeContextProvider>
                  </AppContextProvider>
                </ToastProvider>
              </CookiesProvider>
            </ThemeProvider>
          </SessionProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}
