import { useState, createContext, Dispatch, SetStateAction } from 'react';
import { RouterConfig } from '../config/router';

export const StatsChangeContext = createContext(null);

export interface StatsVariant {
  title: string;
  over: RouterConfig;
  under: RouterConfig;
}

export interface StatsChangeContextProps {
  isUnderOver: boolean;
  setIsUnderOver: Dispatch<SetStateAction<boolean>>;
  allStatsVariants: StatsVariant[];
  setAllStatsVariants: Dispatch<SetStateAction<StatsVariant[]>>;
}

export function StatsChangeContextProvider({ children }) {
  const [isUnderOver, setIsUnderOver] = useState(false);
  const [allStatsVariants, setAllStatsVariants] = useState<StatsVariant[]>([]);

  const value = {
    isUnderOver,
    setIsUnderOver,
    allStatsVariants,
    setAllStatsVariants,
  };
  return <StatsChangeContext.Provider value={value}>{children}</StatsChangeContext.Provider>;
}
