import { dark, IThemeColors, light } from "./themeColors";

export interface ICssVariables {
  baseFont: string;
  secondFont: string;

  lightColors: IThemeColors;
  darkColors: IThemeColors;
  DividerContainerWidth: string;
}

export const CSSVars: ICssVariables = {
  baseFont: `'Montserrat', sans-serif`,
  // baseFont: `'Roboto', sans-serif`,
  //   baseFont: `'Poppins', sans-serif`,
  secondFont: `'Roboto', sans-serif`,

  lightColors: light,
  darkColors: dark,
  DividerContainerWidth: "0.1rem",
};
