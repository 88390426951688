import React from 'react';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import styled from 'styled-components';

import { Container, MainContainer, Header, Sidebar } from './LayoutStyles';
import { TopBar } from './TopBar';
import { NavigationBar } from './Navigation';
import { navigationConfig } from '../config/navigation';
import { useAppContext } from '../hooks/useAppContext';
import { CookieBanner } from './CookieBanner';

const HeaderLabel = styled.div`
  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-area: content;
    height: 12rem;
    background: ${({ theme }) => theme.backgroundNavigation};
    position: relative;
  }
`;

const StyledPageTitle = styled.h3`
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    color: ${({ theme }) => theme.backgroundLight};
    padding-left: 1rem;
    position: absolute;
    display: block;
  }
`;
export const Layout = ({ children }) => {
  const [open, setOpen] = React.useState(true);
  const { pageTitle } = useAppContext();

  return (
    <Container isSidebarOpen={open}>
      <Header>
        <TopBar />
      </Header>
      <Sidebar isSidebarOpen={open}>
        <Hidden smDown>
          <NavigationBar menuConfig={navigationConfig} showTitle={open} />
        </Hidden>
      </Sidebar>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <HeaderLabel>
          <StyledPageTitle>{pageTitle}</StyledPageTitle>
          <MainContainer isSidebarOpen={open}>{children}</MainContainer>
        </HeaderLabel>
      </MuiPickersUtilsProvider>
      <CookieBanner />
    </Container>
  );
};
