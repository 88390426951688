export enum RouterConfig {
  Prediction = '/prediction',
  Late = '/late',
  Breaker = '/breaker',
  AllMatches = '/all-matches',

  YellowCard = '/yellow',
  CardOverZero = '/yellow/over-zero',
  CardOverOne = '/yellow/over-one',
  CardOverTwo = '/yellow/over-two',
  CardOverThree = '/yellow/over-three',
  CardOverFour = '/yellow/over-four',
  CardOverFive = '/yellow/over-five',
  CardOverSix = '/yellow/over-six',
  CardUnderZero = '/yellow/under-zero',
  CardUnderOne = '/yellow/under-one',
  CardUnderTwo = '/yellow/under-two',
  CardUnderThree = '/yellow/under-three',
  CardUnderFour = '/yellow/under-four',
  CardUnderFive = '/yellow/under-five',
  CardUnderSix = '/yellow/under-six',

  RedCard = '/red',
  RedCardStat = '/red/red',
  NoRedCardStat = '/red/no-red',

  Under = '/under',
  OverZero = '/under/over-zero',
  OverOne = '/under/over-one',
  OverTwo = '/under/over-two',
  OverThree = '/under/over-three',
  OverFour = '/under/over-four',
  UnderZero = '/under/under-zero',
  UnderOne = '/under/under-one',
  UnderTwo = '/under/under-two',
  UnderThree = '/under/under-three',
  UnderFour = '/under/under-four',

  Draw = '/draw',
  DrawStats = '/draw/draw',
  NoDrawStats = '/draw/no-draw',

  Btts = '/btts',
  BttsStat = '/btts/btts',
  BttsOverTwoStat = '/btts/btts-over-two',
  BttsOverThreeStat = '/btts/btts-over-three',
  NoBttsStat = '/btts/no-btts',

  Corner = '/corner',

  CornerOverSix = '/corner/over-six',
  CornerOverSeven = '/corner/over-seven',
  CornerOverEight = '/corner/over-eight',
  CornerOverNine = '/corner/over-nine',
  CornerOverTen = '/corner/over-ten',
  CornerOverEleven = '/corner/over-eleven',
  CornerOverTwelve = '/corner/over-twelve',
  CornerUnderSix = '/corner/under-six',
  CornerUnderSeven = '/corner/under-seven',
  CornerUnderEight = '/corner/under-eight',
  CornerUnderNine = '/corner/under-nine',
  CornerUnderTen = '/corner/under-ten',
  CornerUnderEleven = '/corner/under-eleven',
  CornerUnderTwelve = '/corner/under-twelve',

  Offside = '/offside',
  OffsideOverTwo = '/offside/over-two',
  OffsideOverThree = '/offside/over-three',
  OffsideOverFour = '/offside/over-four',
  OffsideUnderTwo = '/offside/under-two',
  OffsideUnderThree = '/offside/under-three',
  OffsideUnderFour = '/offside/under-four',

  Foul = '/foul',
  FoulOver19 = '/foul/over-19',
  FoulOver20 = '/foul/over-20',
  FoulOver21 = '/foul/over-21',
  FoulOver22 = '/foul/over-22',
  FoulOver23 = '/foul/over-23',
  FoulOver24 = '/foul/over-24',
  FoulOver25 = '/foul/over-25',
  FoulOver26 = '/foul/over-26',
  FoulUnder19 = '/foul/under-19',
  FoulUnder20 = '/foul/under-20',
  FoulUnder21 = '/foul/under-21',
  FoulUnder22 = '/foul/under-22',
  FoulUnder23 = '/foul/under-23',
  FoulUnder24 = '/foul/under-24',
  FoulUnder25 = '/foul/under-25',
  FoulUnder26 = '/foul/under-26',
}
