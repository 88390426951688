import styled, { css } from 'styled-components';
type SidebarProps = {
  isSidebarOpen?: boolean;
};
export const Container = styled.div<SidebarProps>`
  min-height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;

  padding-bottom: var(--mobileNavigationHeight);
  transition: 0.01s linear;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 0;
    padding-bottom: 0;

    display: grid;

    grid-template-columns: min-content 1fr;
    /* grid-template-rows: var(--headerHeight) auto var(--headerHeight); */
    grid-template-rows: auto;
    grid-template-areas: 'sidebar content';
    /* "sidebar content" */
    /* "sidebar content"; */
  }
`;

export const mobileContainerFullWidthMixin = css`
  padding-left: var(--mobileContainerPadding);
  padding-right: var(--mobileContainerPadding);
  margin-left: calc(-1 * var(--mobileContainerPadding));
  margin-right: calc(-1 * var(--mobileContainerPadding));
`;

export const MainContainer = styled.div<SidebarProps>`
  flex-grow: 1;

  transition: 0.1s linear;

  display: flex;
  background-color: white;
  border-radius: 25px;
  flex-direction: column;
  padding: 0 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 1rem;
    padding-bottom: 2rem;
    margin-top: 5rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

export const Footer = styled.div`
  grid-area: footer;
`;

export const Header = styled.div`
  display: flex;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.backgroundHeader};
  color: white;
  height: var(--headerHeight);
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
  margin-bottom: -2rem;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    background-color: ${({ theme }) => theme.backgroundHeader};
    grid-area: header;
    display: none;
  }
`;

export const Sidebar = styled.aside<SidebarProps>`
  display: none;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 100%;
    z-index: 11;
    background-color: ${({ theme }) => theme.backgroundNavigation};
    /* box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px; */

    background: ${({ theme }) => theme.backgroundHeader};
    width: var(--sidebarFullWidth);
    height: 100vh;
    grid-area: sidebar;
    display: grid;
    transition: 0.1s linear;
  }
`;
