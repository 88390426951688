import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useCookies } from 'react-cookie';
import Button from '@material-ui/core/Button';
import Link from 'next/link';
import styled from 'styled-components';

const StyledA = styled.a`
  color: ${({ theme }) => theme.colorActive};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const COOKIE_NAME = 'cookie-banner-accepted';

export const CookieBanner = () => {
  const [cookies, setCookies] = useCookies([COOKIE_NAME]);

  // if (cookies[COOKIE_NAME]) {
  //   return null;
  // }

  return (
    <Snackbar
      open={!cookies[COOKIE_NAME]}
      action={
        <Button
          key="accept"
          color="secondary"
          size="small"
          onClick={() => {
            setCookies(COOKIE_NAME, 'true', { path: '/', maxAge: 60 * 60 * 24 * 365 });
          }}
        >
          Akceptuje
        </Button>
      }
      message={
        <p id="message-id">
          Ta strona używa plików cookies. <br /> Bez nich internet w ogóle by nie działał tak jak powinien. <br /> Szczegóły znajdziesz w{' '}
          <Link href="/privacy-policy" passHref>
            <StyledA>polityce prywatności</StyledA>
          </Link>
        </p>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    />
  );
};
