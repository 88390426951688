import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Image from 'next/image';
import { useSession, signIn, signOut } from 'next-auth/react';
import Link from 'next/link';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton';

const StyledUserInfoText = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  /* margin-left: 10px; */
  padding-bottom: 1rem;
`;
const AvatarContainer = styled.div`
  display: flex;
  overflow: hidden;
  /* width: 75px;
  height: 75px; */
  cursor: pointer;
  padding: 1rem;
  align-items: center;

  &:hover {
    border-radius: 20px;

    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
    background-color: rgba(209, 209, 240, 0.2);
  }
`;

const AvatarBorder = styled.div`
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 50%;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  padding-bottom: 1rem;
`;

const StyledContainer = styled.div`
  display: flex;
  padding: 0 1rem;
  flex-direction: column;
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  color: ${({ theme }) => theme.colorSecondary};
`;

const StyledMenuItem = styled(MenuItem)`
  &&& {
    &.MuiMenuItem-root {
      padding: 0;
    }
  }
`;

const StyledLink = styled.a`
  padding: 6px 16px;
  display: flex;
  width: 100%;
`;

export const UserAvatar = () => {
  const { data: session } = useSession();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <StyledContainer>
      {session ? (
        <>
          <FlexContainer aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
            <AvatarContainer>
              <AvatarBorder>
                <Image
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  src={session?.user?.image || '/avatar.png'}
                  width={75}
                  height={75}
                  objectFit="cover"
                />
              </AvatarBorder>

              <StyledArrowDropDownIcon fontSize="large" />
            </AvatarContainer>
          </FlexContainer>
          {/* <AvatarContainer>
            <div>
              <Image
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                src={session?.user?.image || '/avatar.png'}
                width={75}
                height={75}
                objectFit="cover"
              />

              <StyledArrowDropDownIcon fontSize="large" />
            </div>
          </AvatarContainer> */}

          <StyledUserInfoText>
            <span>{session?.user?.name}</span>
            <span>{session?.user?.email}</span>
          </StyledUserInfoText>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              signOut();
            }}
          >
            Wyloguj się
          </Button>
        </>
      ) : (
        <>
          <FlexContainer aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
            <AvatarContainer>
              <Image src="/avatar.png" width={75} height={75} />
              <StyledArrowDropDownIcon fontSize="large" />
            </AvatarContainer>
          </FlexContainer>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              signIn();
            }}
          >
            Zaloguj się
          </Button>
        </>
      )}

      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose} TransitionComponent={Fade}>
        {/* {session ? (
          <StyledMenuItem onClick={handleClose}>
            <Link href="#" passHref>
              <StyledLink>Moje konto</StyledLink>
            </Link>
          </StyledMenuItem>
        ) : null} */}
        {/* <StyledMenuItem onClick={handleClose}>
          <Link href="/favourite-leagues" passHref>
            <StyledLink>Ulubione ligi</StyledLink>
          </Link>
        </StyledMenuItem> */}
        <StyledMenuItem onClick={handleClose}>
          <Link href="/privacy-policy" passHref>
            <StyledLink>Polityka prywatności</StyledLink>
          </Link>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClose}>
          <Link href="#" passHref>
            <StyledLink>Regulamin</StyledLink>
          </Link>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClose}>
          <Link href="/about" passHref>
            <StyledLink>O aplikacji</StyledLink>
          </Link>
        </StyledMenuItem>
      </Menu>
    </StyledContainer>
  );
};
