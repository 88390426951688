import React from 'react';
// import { Link } from "./Link";
import List from '@material-ui/core/List';
import { navigationConfig } from '../config/navigation';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Link from 'next/link';

export const Item = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: var(--mobileNavigationHeight);
  font-size: 1rem;

  color: white;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  display: grid;
  grid-template-columns: 50px 1fr;

  > img {
    width: 2.5rem;
    &.active {
      display: none;
    }
  }

  &.active,
  &:hover {
    img {
      &.active {
        display: block;
      }
      &.not-active {
        display: none;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: auto;
    padding: 0.3rem 1.2rem 0.3rem 0;
    border-radius: 0.4rem;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 1.2rem;

    > img {
      width: 2.5rem;
      margin-right: 0.6rem;
    }
    > span {
      white-space: nowrap;
      display: inline-block;
      /* font-weight: bold; */
      max-width: 8.5rem;
    }
  }
`;

const StyledListItem = styled.li<{ isActive: boolean }>`
  padding-left: 1rem;
  padding-right: 1rem;
  /* .active-route {
    background: rgb(255,235,59);
    background: linear-gradient(90deg, rgba(255,235,59,1) 0%, rgba(255,235,59,1) 9%, rgba(0,77,64,1) 100%);
  } */

  ${({ isActive }) =>
    isActive &&
    `
    background: #FFC000;
    background: linear-gradient(90deg, #FFC000 0%, rgba(255, 192, 0, 0) 100%);
    `}

  &:hover {
    background: #ffc000;
    background: linear-gradient(90deg, #ffc000 0%, rgba(255, 192, 0, 0) 100%);
  }
`;

const StyledList = styled(List)`
  overflow-y: auto;
`;
export const NavigationItems = () => {
  const { asPath } = useRouter();

  return (
    <StyledList>
      {navigationConfig.map(({ title, navigation, icon, activeIcon }) => {
        const isActive = asPath === navigation || asPath === navigation || asPath.includes(`${navigation.toString()}/`);

        return (
          <StyledListItem key={navigation} isActive={isActive}>
            <Link href={navigation} passHref>
              <Item className={`${isActive ? 'active' : ''} `}>
                <img className="not-active" src={icon} alt={`Navigation ${title} icon`} />
                <img className="active" src={activeIcon} alt={`Navigation  ${title} active icon`} />
                <span>{title}</span>
              </Item>
            </Link>
          </StyledListItem>
        );
      })}
    </StyledList>
  );
};
