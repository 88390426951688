import { useCallback, useEffect, useMemo, useState, createContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import styled from 'styled-components';

const AUTO_DISMISS = 5000;

export const ToastContext = createContext(null);

export interface AlertProps {
  key: string;
  message: string;
  type?: Color;
}

const StyledMessage = styled.div`
  white-space: pre-wrap;
`;
export function ToastProvider({ children }) {
  const [alerts, setAlerts] = useState<AlertProps[]>([]);

  // const activeAlertIds = alerts.join(',')
  // useEffect(() => {
  //   if (activeAlertIds.length > 0) {
  //     const timer = setTimeout(() => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)), AUTO_DISMISS)
  //     return () => clearTimeout(timer)
  //   }
  // }, [activeAlertIds])

  // const addAlert = (alert) => setAlerts((alerts) => [alert, ...alerts])
  const addAlert = useCallback((content: AlertProps) => setAlerts((alerts) => [content, ...alerts]), []);

  const value = useMemo(() => ({ addAlert }), [addAlert]);

  const handleClose = (id: string) => {
    setAlerts((oldAlerts) => {
      return oldAlerts.filter((alert) => alert.key !== id);
    });
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
      {alerts.map((alert) => {
        return (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            key={alert.key}
            open={true}
            autoHideDuration={AUTO_DISMISS}
            onClose={() => handleClose(alert.key)}
          >
            <MuiAlert onClose={() => handleClose(alert.key)} severity={alert.type || 'success'}>
              <StyledMessage>{alert.message}</StyledMessage>
            </MuiAlert>
          </Snackbar>
        );
      })}
    </ToastContext.Provider>
  );
}
