import { useState, createContext, Dispatch, SetStateAction } from 'react';
import { CalculateHistoryUnderOver } from '../utils/stats';

export const AppContext = createContext(null);

export type IStatPassConditionContext =
  | {
      isOverStats: false;
    }
  | ({
      isOverStats: true;
    } & Pick<CalculateHistoryUnderOver, 'isOver' | 'condition'>);

export interface AppContextProps {
  pageTitle: string;
  setPageTitle: Dispatch<SetStateAction<string>>;
  setSelectedStatsCondition: Dispatch<SetStateAction<IStatPassConditionContext | null>>;
  selectedStatsCondition: IStatPassConditionContext | null;
}

export function AppContextProvider({ children }) {
  const [pageTitle, setPageTitle] = useState('');
  // get params types from function isStatPassCondition from utils/stats.ts

  const [selectedStatsCondition, setSelectedStatsCondition] = useState<IStatPassConditionContext | null>(null);

  const value = {
    pageTitle,
    setPageTitle,
    selectedStatsCondition,
    setSelectedStatsCondition,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
