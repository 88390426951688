import React, { Children } from 'react';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

interface ILinkProps extends LinkProps {
  children: any;
  activeClassName?: string;
}
export const Link = ({ as, href, children, activeClassName = 'active-route' }: ILinkProps) => {
  const { asPath } = useRouter();
  const child = Children.only(children);
  const childClassName = child.props.className || '';
  // const className =
  //   asPath === href || asPath === as
  //     ? `${childClassName} ${activeClassName}`.trim()
  //     : childClassName;
  const className =
    asPath === href || asPath === as || asPath.includes(href.toString()) ? `${childClassName} ${activeClassName}`.trim() : childClassName;

  return (
    <NextLink href={href} passHref>
      {React.cloneElement(children, { className: className || null })}
    </NextLink>
  );
};
